@tailwind base;
@layer base {
  img {
    @apply inline-block;
  }
}
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.toggle-bg:after {
  content: '';
  @apply absolute top-0.5 left-0.5 bg-white border border-gray-300 rounded-full h-5 w-5 transition shadow-sm;
}

input:checked + .toggle-bg:after {
  transform: translateX(100%);
  @apply border-white;
}

input:checked + .toggle-bg {
  @apply bg-blue-500/80 border-blue-500/80;
}


.dark {
  background-color: #1a202c;

  .time-slider {
    .irs--round .irs-min, .irs--round .irs-max {
      color: rgb(228 228 231);
      font-size: 12px;
      margin-top: 15px;
    }

    .irs--round .irs-grid-text {
      font-size: 11px;
      color: rgb(228 228 231);
    }

    .irs--round .irs-single {
      color: rgb(228 228 231);
      font-size: 12px;
      margin-top: 5px;
    }

    .irs--round .irs-handle {
      width: 20px;
      height: 20px;
      top: 28px;
    }
  }
}


html {
  background-color: #fff;
}

.time-slider {
  .irs--round .irs-min, .irs--round .irs-max {
    color: rgb(15 23 42);
    font-size: 12px;
    margin-top: 15px;
  }

  .irs--round .irs-grid-text {
    font-size: 11px;
    color: rgb(15 23 42);
  }

  .irs--round .irs-single {
    color: rgb(228 228 231);
    font-size: 12px;
    margin-top: 5px;
  }

  .irs--round .irs-handle {
    width: 20px;
    height: 20px;
    top: 28px;
  }
}

.point-table {
  td {
    padding: 4px;
    text-align: center;
    vertical-align: middle;
    border: 1px solid #ddd;

    &:nth-child(11n+1){
        @apply border-r-[2px] border-r-gray-400 dark:border-r-white;
    }
    &:last-child {
      @apply border-r-[1px] border-[#ddd] cursor-auto;
    }
  }

  th {
    padding: 4px;
    text-align: center;
    vertical-align: middle;
    border: 1px solid #ddd;
  }

  thead th:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
    @apply bg-white dark:bg-slate-800;

    &:after{
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      display: inline-block;
      width: 1px;
      background-color: white;
    }

    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      display: inline-block;
      width: 1px;
      background-color: white;
    }
  }

  tbody th {
    position: sticky;
    left: 0;
    z-index: 1;
    @apply bg-white dark:bg-slate-800;

    &:after{
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      display: inline-block;
      width: 1px;
      background-color: white;
    }

    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      display: inline-block;
      width: 1px;
      background-color: white;
    }
  }


  // icons for POINT TABLE
  .icon {
    display: inline-block;
    font-size: .25em;
    height: 3.5em;
    position: relative;
    width: 1em
  }

  .cloud {
    border-radius: 50%;
    box-shadow: -2.1875em .6875em 0 -.6875em, 2.0625em .9375em 0 -.9375em, 0 0 0 .375em #000, -2.1875em .6875em 0 -.3125em #000, 2.0625em .9375em 0 -.5625em #000;
    color: #fff;
    height: 3.6875em;
    left: 50%;
    margin: -1.84375em;
    top: 50%;
    width: 3.6875em;
  }

  .cloud, .cloud:after {
    background: currentColor;
    position: absolute
  }

  .cloud:after {
    bottom: 0;
    box-shadow: 0 .4375em 0 -.0625em #000;
    content: "";
    display: block;
    height: 1em;
    left: -.5em;
    width: 4.5625em
  }

  .cloud:nth-child(2) {
    animation: cloud 4s linear infinite;
    background: #000;
    box-shadow: -2.1875em .6875em 0 -.6875em #000, 2.0625em .9375em 0 -.9375em #000, 0 0 0 .375em #000, -2.1875em .6875em 0 -.3125em #000, 2.0625em .9375em 0 -.5625em #000;
    opacity: .3;
    transform: scale(.5) translate(6em, -3em);
    z-index: 0
  }

  .cloud:nth-child(2):after {
    background: #000
  }

  .sun {
    animation: spin 12s linear infinite;
    background: #FFD700;
    border-radius: 50%;
    box-shadow: 0 0 0 .375em #FFD700;
    color: #fff;
    height: 2.5em;
    left: 50%;
    margin: -1.25em;
    position: absolute;
    top: 50%;
    width: 2.5em
  }

  .rays {
    border-radius: .4em;
    left: 50%;
    margin-left: -.3875em;
    top: -2em
  }

  .rays, .rays:after, .rays:before {
    background: #FFD700;
    box-shadow: 0 5.375em #FFD700;
    display: block;
    height: 1.125em;
    position: absolute;
    width: .775em
  }

  .rays:after, .rays:before {
    border-radius: .8em;
    content: "";
    left: 0;
    top: 0;
    transform: rotate(60deg);
    transform-origin: 50% 3.25em
  }

  .rays:before {
    transform: rotate(120deg)
  }

  .cloud + .sun {
    margin: -2em 1em
  }

  .lightning, .rain, .snow {
    height: 3.75em;
    margin: .375em 0 0 -2em;
    width: 3.75em
  }

  .lightning, .rain, .rain:after, .snow {
    left: 50%;
    position: absolute;
    top: 50%;
  }

  .rain:after {
    animation: rain 3s linear infinite;
    background: #0cf;
    border-radius: 100% 0 60% 50%/60% 0 100% 50%;
    box-shadow: .625em .875em 0 -.125em hsla(0, 0%, 100%, .2), -.875em 1.125em 0 -.125em hsla(0, 0%, 100%, .2), -1.375em -.125em 0 hsla(0, 0%, 100%, .2);
    content: "";
    height: 1.125em;
    margin: -1em 0 0 -.25em;
    transform: rotate(-28deg);
    width: 1.125em
  }

  .bolt {
    animation: lightning 2s linear infinite;
    color: #000;
    left: 50%;
    margin: -.25em 0 0 -.125em;
    opacity: .3;
    position: absolute;
    top: 50%
  }

  .bolt:nth-child(2) {
    animation: lightning 1.5s linear infinite;
    height: .25em;
    margin: -1.75em 0 0 -1.875em;
    opacity: .2;
    transform: translate(2.5em, 2.25em);
    width: .5em
  }

  .bolt:after, .bolt:before {
    border-color: transparent currentcolor currentcolor transparent;
    border-style: solid;
    border-width: 1.25em .75em .75em .5em;
    content: "";
    left: 50%;
    margin: -1.625em 0 0 -1.0125em;
    position: absolute;
    top: 50%;
    transform: skewX(-10deg);
    z-index: 2
  }

  .bolt:after {
    border-color: currentcolor transparent transparent currentcolor;
    border-style: solid;
    border-width: .75em .5em 1.25em .75em;
    margin: -.25em 0 0 -.25em;
    transform: skewX(-10deg)
  }

  .bolt:nth-child(2):before {
    border-color: transparent currentcolor currentcolor transparent;
    border-style: solid;
    border-width: .625em .375em .375em .25em;
    margin: -.75em 0 0 -.5em
  }

  .bolt:nth-child(2):after {
    border-color: currentcolor transparent transparent currentcolor;
    border-style: solid;
    border-width: .375em .25em .625em .375em;
    margin: -.125em 0 0 -.125em
  }

  .flake:after, .flake:before {
    animation: spin 8s linear infinite reverse;
    color: #000;
    content: "\2744";
    left: 50%;
    margin: -1.025em 0 0 -1.0125em;
    opacity: .2;
    position: absolute;
    top: 50%
  }

  .flake:after {
    animation: spin 14s linear infinite;
    font-size: 1.5em;
    margin: .125em 0 0 -1em;
    opacity: .4
  }

  .flake:nth-child(2):before {
    animation: spin 10s linear infinite;
    font-size: 1.25em;
    margin: -.5em 0 0 .25em;
    opacity: .2
  }

  .flake:nth-child(2):after {
    animation: spin 16s linear infinite reverse;
    font-size: 2em;
    margin: .375em 0 0 .125em;
    opacity: .4
  }

  @keyframes spin {
    to {
      transform: rotate(1turn)
    }
  }
  @keyframes cloud {
    0% {
      opacity: 0
    }
    50% {
      opacity: .3
    }
    to {
      opacity: 0;
      transform: scale(.5) translate(-200%, -3em)
    }
  }
  @keyframes rain {
    0% {
      background: #0cf;
      box-shadow: .625em .875em 0 -.125em hsla(0, 0%, 100%, .2), -.875em 1.125em 0 -.125em hsla(0, 0%, 100%, .2), -1.375em -.125em 0 #0cf
    }
    25% {
      box-shadow: .625em .875em 0 -.125em hsla(0, 0%, 100%, .2), -.875em 1.125em 0 -.125em #0cf, -1.375em -.125em 0 hsla(0, 0%, 100%, .2)
    }
    50% {
      background: hsla(0, 0%, 100%, .3);
      box-shadow: .625em .875em 0 -.125em #0cf, -.875em 1.125em 0 -.125em hsla(0, 0%, 100%, .2), -1.375em -.125em 0 hsla(0, 0%, 100%, .2)
    }
    to {
      box-shadow: .625em .875em 0 -.125em hsla(0, 0%, 100%, .2), -.875em 1.125em 0 -.125em hsla(0, 0%, 100%, .2), -1.375em -.125em 0 #0cf
    }
  }
  @keyframes lightning {
    45% {
      background: #000;
      color: #000;
      opacity: .2
    }
    50% {
      background: #0cf;
      color: #0cf;
      opacity: 1
    }
    55% {
      background: #000;
      color: #000;
      opacity: .2
    }
  }
}

.highcharts-credits {
  display: none;
}



